import React from "react";
import { PersonVideo, CameraReels } from "react-bootstrap-icons";

import { SidebarProps } from "../@types/Interfaces";
import NavButton from "../components/NavButton";

const Sidebar = (props: SidebarProps) => {
  // props

  const { toggle } = props;

  //  sidebar navigation

  const navLinks = [
    { href: "/", title: "Videos", icon: <CameraReels /> },
    { href: "/selectedPlayers", title: "joueurs", icon: <PersonVideo /> },
    // { href: "/users", title: "Chan", icon: <PersonVideo /> },
  ];

  return (
    <div className={toggle ? "min__SideBar SideBar" : "SideBar"}>
      <div className="sidebar__logo">
        <img
          src={!toggle ? "/assets/images/kickof-logo.png" : "/assets/images/kickof-logo-3.png"}
          alt="Kickof"
          className="img-fluid"
          width={!toggle ? 150 : 60}
          height={!toggle ? 45 : 40}
        />
      </div>
      <div className="sidebar__menu">
        <ul>
          {navLinks.map((item, index) => {
            return (
              <NavButton key={index} href={item.href} title={item.title}>
                {item.icon}
              </NavButton>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default React.memo(Sidebar);
