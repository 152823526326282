import { createApi } from "@reduxjs/toolkit/query/react";

import { MyBaseQueryFn } from "../apiBaseQueryFn";
import getBaseQuery from "../baseQueryWithReauth";
import { listRes, updateReq, updateRes } from "./types";

// user = auth
export const selectedPlayersApi = createApi({
  reducerPath: "selectedPlayers-api",
  baseQuery: getBaseQuery("/auth/selectedplayers") as MyBaseQueryFn,
  tagTypes: ["SelectedPlayers"],
  endpoints: builder => ({
    list: builder.query<listRes, void>({
      query: () => ({
        url: "/list",
        method: "GET",
      }),
      providesTags: [{ type: "SelectedPlayers", id: "list" }],
    }),
    update: builder.mutation<updateRes, updateReq>({
      query: body => ({
        url: "/update",
        method: "PUT",
        body,
      }),
      invalidatesTags: [{ type: "SelectedPlayers", id: "list" }],
    }),
  }),
});
