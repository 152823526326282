import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { IAuth } from "../../interfaces/IAuth";
import { IClub } from "../../interfaces/IClub";

interface IInitialState {
  accessToken: string;
  refreshToken: string;
  club: IClub | null;
}
const authSlice = createSlice({
  name: "auth",
  initialState: {
    club: null,
    accessToken: "",
    refreshToken: "",
    signupCompleted: false,
    isEmailValid: false,
  } as IInitialState,
  reducers: {
    setCurrentAuth: (state, { payload }: PayloadAction<IInitialState | null>) => {
      state.club = payload?.club || null;
      state.refreshToken = payload?.refreshToken || "";
      state.accessToken = payload?.accessToken || "";
    },
    setCurrentAuthTokens: (state, { payload }: PayloadAction<{ refreshToken: string; accessToken: string } | null>) => {
      state.refreshToken = payload?.refreshToken!;
      state.accessToken = payload?.accessToken!;
    },
    setCurrentAuthProfile: (state, { payload }: PayloadAction<{ club: IClub | null; signupCompleted?: boolean }>) => {
      state.club = payload?.club!;
    },
  },
});

export default authSlice;
export const { setCurrentAuth, setCurrentAuthTokens, setCurrentAuthProfile } = authSlice.actions;
