import { configureStore, combineReducers, Action } from "@reduxjs/toolkit";
import { persistStore, persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from "redux-persist";

import storage from "redux-persist/lib/storage";

//apis
import { authApi } from "./apis/auth";
import { postApi } from "./apis/post";
import { selectedPlayersApi } from "./apis/selectedPlayers";

//slices
import authSlice from "./slices/auth";
import configSlice from "./slices/config";

//commons
// import { unauthenticatedMiddleware } from './middlewares/unauthenticated';
import { RESET_STATE_ACTION_TYPE } from "./actions/resetState";

const combinedReducers = combineReducers({
  [authApi.reducerPath]: authApi.reducer,
  [postApi.reducerPath]: postApi.reducer,
  [selectedPlayersApi.reducerPath]: selectedPlayersApi.reducer,
  auth: authSlice.reducer,
  config: configSlice.reducer,
});

const rootReducer = (state: any, action: Action) => {
  if (action.type === RESET_STATE_ACTION_TYPE) {
    state = undefined;
  }
  return combinedReducers(state, action);
};

const reducer = persistReducer(
  {
    key: "root",
    storage,
    version: 1,
    blacklist: [authApi.reducerPath, postApi.reducerPath, selectedPlayersApi.reducerPath],
  },
  rootReducer
);

export const store = configureStore({
  reducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat([authApi.middleware, postApi.middleware, selectedPlayersApi.middleware]),
});
export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
