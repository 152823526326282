import React from "react";
import ReactDom from "react-dom";
import { BrowserRouter } from "react-router-dom";
import "antd/dist/antd.min.css";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";

import App from "./App";
import "./styles/index.scss";
import { DrawerProvider } from "./context/Drawer";
import { persistor, store } from "./store/index";
import { AuthProvider } from "./context/Auth";

// Application  Root
const _AppRoot = (
  <React.StrictMode>
    <BrowserRouter>
      <Provider store={store}>
        <PersistGate persistor={persistor} loading={null}>
          <AuthProvider>
            <DrawerProvider>
              <App />
            </DrawerProvider>
          </AuthProvider>
        </PersistGate>
      </Provider>
    </BrowserRouter>
  </React.StrictMode>
);

const documentRoot = document.getElementById("root");

ReactDom.render(_AppRoot, documentRoot);
