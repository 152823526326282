import React from "react";
import { Route, Routes } from "react-router-dom";
import { RootAppProps } from "./@types/Interfaces";
import LoadingSpinner from "./components/LoadingSpinner";

import Layout from "./layout";
import ProtectedPage from "./modules/Auth/ProtectedPage";

// pages

const Videos = React.lazy(() => import("./pages/Videos"));
const ErrorPage = React.lazy(() => import("./pages/_errorPage"));
const Login = React.lazy(() => import("./pages/Login"));
const SelectedPlayers = React.lazy(() => import("./pages/SelectedPlayers"));
//  App Root

function App(props: RootAppProps) {
  // props
  const {} = props;

  // loading spinner
  const loadingSpinner = (
    <>
      <LoadingSpinner />
    </>
  );

  return (
    <div className="App">
      <React.Suspense fallback={loadingSpinner}>
        <Routes>
          {/* pages with layout */}

          <Route
            path="/"
            element={
              <ProtectedPage>
                <Layout />
              </ProtectedPage>
            }
          >
            <Route index element={<Videos />} />
            <Route path="/selectedPlayers" element={<SelectedPlayers />} />
          </Route>
          {/* error page */}
          <Route path="*" element={<ErrorPage />} />

          {/* login page */}
          <Route path="/login" element={<Login />} />
        </Routes>
      </React.Suspense>
    </div>
  );
}

export default App;
